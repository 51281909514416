import React from "react"
import { Helmet } from "react-helmet"

import CocaColaImg from "../images/coke.svg"
import ConservationImg from "../images/conservation.svg"
import OrkinImg from "../images/orkin.svg"
import PriviaImg from "../images/privia.svg"
import ShureImg from "../images/shure.svg"
import WCSImg from "../images/wcs.svg"
import BRTImg from "../images/brt.svg"
import DanaherImg from "../images/danaher.svg"
import Github from "../images/github.svg"
import Linkedin from "../images/linkedin.svg"

function generateClientImages() {
  const images = [
      {src: ConservationImg, alt: "Conservation International"}, 
      {src: PriviaImg, alt: "Privia Health"}, 
      {src: ShureImg, alt: "Shure Microphones"},
      {src: WCSImg, alt: "Wildlife Conservation Society"}, 
      {src: BRTImg, alt: "Business Roundtable"}, 
      {src: OrkinImg, alt: "Orkin Pest Control"},
      {src: CocaColaImg, alt: "Coca-Cola"}, 
      {src: DanaherImg, alt: "Danaher"}, 
    ]
  
  return images.map(image => (
    <li className="w-1/2 md:w-1/4 p-8 flex items-center justify-center">
      <img src={image.src} alt={image.alt} className="w-full max-h-xs filter-sepia text-orange-900 fill-current object-contain"/>
    </li>
  ))
}

function generateIcons() {
  const icons = [
      {src: Github, alt: "Github", url: "https://github.com/benjtinsley"},
      {src: Linkedin, alt: "LinkedIn", url: "https://www.linkedin.com/in/benj-tinsley/"}, 
    ]
  
  return icons.map(icon => (
    <li className="p-8 flex items-center justify-center">
      <a href={icon.url}>
        <img src={icon.src} alt={icon.alt} className="text-white fill-current"/>
      </a>
    </li>
  ))
}

function generateH2(text) {
  return <h2 className="font-display text-5xl text-center mb-8">{text}</h2>
}

function generateBlogPosts() {
  const posts = [
    {title: 'Extending Your App Beyond the Web with Electron', url: 'https://www.viget.com/articles/extending-your-app-beyond-the-web-with-electron/', border: 'border-pink-500'},
    {title: 'Tailwind CSS for the Experienced Flyer', url: 'https://www.viget.com/articles/tailwind-css-for-the-experienced-flyer/', border: 'border-purple-800'},
    {title: 'Getting Started with HTTP/2', url: 'https://www.viget.com/articles/getting-started-with-http-2-part-1/', border: 'border-green-400'},
  ]

  return posts.map(post => (
    <li className="w-full md:w-1/3 mb-16 md:mb-0 md:pr-16 flex md:min-h-xs">
      <a href={post.url} rel="nofollow" className={`block w-full pb-4 md:pb-16 ${post.border} border-b-4 md:hover:border-b-24 ease-out duration-300 transition-all`}>
        {post.title}
      </a>
    </li>
  ))
}

export default function Home() {
  return (
    <>
      <Helmet htmlAttributes={{ lang : "en" }}>
        <meta charSet="utf-8" />
        <title>Benjamin Tinsley</title>
        <meta meta="description" content="Front-End Developer" />
        <link rel="canonical" href="https://bentinsley.dev" />
      </Helmet>
      
      <main>
        <div className="bg-indigo-800 font-body">
          <h1 className="sr-only">Benjamin Tinsley</h1>
          <div className="container px-8 mx-auto pt-24 md:pt-48 pb-4 md:pb-16">
            <p className="text-yellow-100 pb-16 lg:pb-0">
              <span className="font-display text-4xl md:text-6xl block mb-4">
                Benjamin Tinsley
              </span>
              <span className="text-xl md:text-3xl tracking-wide">
                <span className="block mb-4">
                is a former technical recruiter and frontend engineer. He is currently a <span className="font-extrabold">computer science student</span>. 
                </span>
                <span className="block">
                  He lives in Colorado and is enrolled at CU Boulder's <a className="underline" href="https://www.colorado.edu/program/cspb/current-student-resources/" rel="nofollow">Post-Baccalaureate Computer Science</a> program. 
                </span>
              </span>
            </p>
            <ul className="flex flex-wrap items-center justify-center md:justify-end">
              { generateIcons() }
            </ul>
          </div>
        </div>

        <div className="bg-orange-100 text-gray-800 font-body">
          <div className="container px-8 mx-auto">
            <div className="pt-32 md:pt-48">
              { generateH2('Clients')}
              <ul className="flex flex-wrap items-center">
                { generateClientImages() }
              </ul>
            </div>

            <div className="pt-32 md:pt-48">
              { generateH2('Toolset & Skills')}
              <ul className="max-w-md mx-auto text-2xl text-center mb-16">
                <li className="inline">Python, </li>
                <li className="inline">C++, </li>
                <li className="inline"><span className="font-extrabold">JavaScript</span>, </li>
                <li className="inline">HTML/CSS, </li>
                <li className="inline"><span className="font-extrabold">React</span>, </li>
                <li className="inline">& </li>
                <li className="inline"><span className="font-extrabold">Ruby on Rails</span></li>
              </ul>

              <p className="text-2xl tracking-wide leading-relaxed">A web that is only visible to a small portion of users is useless. Benjamin focuses on <span className="font-extrabold">accessibility</span>, <span className="font-extrabold">performance</span> and <span className="font-extrabold">cross-browser testing</span> to bring functional and beautiful experiences to all users.</p>
            </div>
            
            <div className="pt-32 md:pt-48 pb-16">
              { generateH2('Selected Writings')}
              <ul className="text-xl flex flex-wrap items-start 2items-stretch">
                {generateBlogPosts()}
              </ul>
            </div>

          </div>
        </div>
      </main>
    </>
  )
}
